import styled from 'styled-components'

export function Coupon(): JSX.Element {
  return (
    <>
      <Section>
        <img
          src="/image/shinhancard/brwnie-coupon-content-background.png"
          alt="신한카드 이벤트 페이지 쿠폰 폭죽 배경"
          width={360}
        />

        <TitleContainer>
          <TitleFirstLine>신한카드 Biz_Plan</TitleFirstLine>
          <TitleSecondLine>
            고객을 위한 <span>5만원 할인</span>
          </TitleSecondLine>
        </TitleContainer>

        <CouponAssetContainer>
          <img src="/image/shinhancard/brwnie-coupon.webp" alt="쿠폰" width={279} />
        </CouponAssetContainer>
      </Section>
    </>
  )
}

const Section = styled.section`
  width: 100vw;
  height: calc(330px + 44px);
  background-color: #1490ef;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
  & > img {
    width: 100%;
    object-fit: cover;
  }
`

const TitleContainer = styled.div`
  position: absolute;
  top: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const TitleFirstLine = styled.span`
  font-family: 'GmarketSansBold';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -1.04px;
  color: #fff;
  & > span {
    font-size: 30px;
  }
`

const TitleSecondLine = styled.h3`
  font-family: 'GmarketSansBold';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -1.04px;
  color: #fff;
  margin-top: 4px;
  & > span {
    color: #fcff10;
  }
`

const CouponAssetContainer = styled.div`
  position: absolute;
  // 44는 바로 이어지는 섹션의 둥근 부분 뒤에 깔릴 배경 높이
  bottom: -40px;
`
