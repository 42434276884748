import { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useHookFormMask } from 'use-mask-input'
import { useCollaboratorRegister } from 'hooks/query/Collaborate'
import AlertModal from 'components/common/AlertModal'
import { AxiosError } from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useToast from 'hooks/useToast'
interface DiscountFormType {
  storeName: string
  name: string
  phone: string
  term: boolean
}

interface AlertProp {
  open: boolean
  title: string
  description: string
}

export function CouponForm() {
  const [openTerm, setOpenTerm] = useState(false)
  const [coupon, setCoupon] = useState<string | null>(null)
  const [openAlert, setOpenAlert] = useState<AlertProp>({
    open: false,
    title: '',
    description: '',
  })
  const toast = useToast()
  const collaboratorRegister = useCollaboratorRegister()

  const { register, handleSubmit, formState, watch } = useForm<DiscountFormType>({
    mode: 'onChange',
  })
  const registerWithMask = useHookFormMask(register)

  const handleFormSubmit = (values: DiscountFormType) => {
    collaboratorRegister.mutate(
      {
        keyCode: 'shinhancard',
        name: values.name,
        phone: values.phone.replace(/-/g, ''),
        store_name: values.storeName,
      },
      {
        onSuccess: (data) => {
          setCoupon(data.code)
          setOpenAlert({
            open: true,
            title: '쿠폰이 발급되었습니다',
            description: `제공 받은 쿠폰 번호를 기억해 주세요.\n캡처해서 보관하는 것을 추천해요!`,
          })
        },
        onError: (error: unknown) => {
          const err = error as AxiosError
          setOpenAlert({
            open: true,
            title: '이미 등록된 전화번호 입니다.',
            description: '신규 회원 가입자만 가능합니다.',
          })
        },
      }
    )
  }

  return (
    <>
      <Section>
        <TitleContainer>
          <SpeechBubble>
            <SpeechBubbleContent>5만원 할인 쿠폰 받는 방법</SpeechBubbleContent>
            <SpeechBubbleTriangle />
          </SpeechBubble>

          <Title>
            아래 정보를 작성 후<br />
            '할인 쿠폰 받기' 버튼을 클릭해주세요.
          </Title>
        </TitleContainer>

        <FormWrap onSubmit={handleSubmit(handleFormSubmit)}>
          <Label>매장명</Label>
          <Input
            type="text"
            {...register('storeName', {
              required: '필수 입력란입니다.',
            })}
          />
          {formState?.errors?.storeName && <ErrorMessage>{formState.errors.storeName.message}</ErrorMessage>}
          <Label>성함</Label>
          <Input
            type="text"
            {...register('name', {
              required: '필수 입력란입니다.',
            })}
          />
          {formState?.errors?.name && <ErrorMessage>{formState.errors.name.message}</ErrorMessage>}
          <Label>휴대전화번호</Label>
          <Input
            type="text"
            {...registerWithMask('phone', ['999-9999-9999'], {
              showMaskOnFocus: false,
              showMaskOnHover: false,
              jitMasking: true,
            })}
          />
          {formState?.errors?.phone && <ErrorMessage>{formState.errors.phone.message}</ErrorMessage>}
          <TermWrap>
            <CheckBox type="checkbox" {...register('term')} />
            <span>
              계속하시면{' '}
              <span className="underline pointer" onClick={() => setOpenTerm(true)}>
                개인정보처리방침
              </span>
              에 동의하게 됩니다.
            </span>
          </TermWrap>

          {openTerm && (
            <PolicySection>
              <article>
                개인정보의 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 이러한 개인정보 수집 이용에 동의하지
                않을 경우 상담 서비스 제공이 불가능합니다.
              </article>
              <article>
                수집항목 : 고객명, 매장명, 전화번호
                <br />
                목적 : 서비스 상담 등 거래관계의 설정
                <br />
                이용 및 보관기간 : 수집된 개인 정보는 정보 기입 후 6개월 간 보관됩니다.
              </article>
            </PolicySection>
          )}

          <>
            {coupon ? (
              <Button type="button">
                <CopyToClipboard
                  text={coupon || ''}
                  onCopy={() => {
                    toast.open('쿠폰코드가 복사되었어요!')
                  }}
                >
                  <span>쿠폰코드 : {coupon || '코드 없음'}</span>
                </CopyToClipboard>
              </Button>
            ) : (
              <Button type="submit" disabled={!watch('term') || collaboratorRegister.isLoading}>
                할인 쿠폰 받기
              </Button>
            )}
          </>
        </FormWrap>
      </Section>

      <AlertModal
        open={openAlert.open}
        onConfirm={() => {
          setOpenAlert({ open: false, title: '', description: '' })
        }}
        confirmText={'닫기'}
        buttonWidth={264}
        title={openAlert.title}
        subline={openAlert.description}
      ></AlertModal>
    </>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100vw;
  background-color: #d6e7fd;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const SpeechBubble = styled.div`
  width: 220px;
  height: 28px;
  border-radius: 9999px;
  background-color: #fcff10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpeechBubbleContent = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.4px;
`

const SpeechBubbleTriangle = styled.span`
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #fcff10 transparent transparent transparent;
`

const Title = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -0.4px;
  margin-top: 12px;
  text-align: center;
`

const FormWrap = styled.form`
  width: 328px;
  padding: 30px 24px;
  border-radius: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`

const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.1px;
  color: var(--system-content-primary);
`

const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #f6f6f6;
  border-radius: 16px;
  border: none;
  outline: none;
  padding: 12px 20px;
`

const TermWrap = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.036px;

    & > a {
      text-decoration: underline;
    }
  }

  .underline {
    text-decoration: underline;
  }

  .pointer {
    cursor: pointer;
  }
`

const CheckBox = styled.input`
  margin-right: 4px;
  border-radius: 6px;
  border: 2px solid #e1e1e1;

  &::after {
    border: 2px solid #e1e1e1;
  }
`

const Button = styled.button`
  margin: 0 auto;
  display: flex;
  min-height: 46px;
  justify-content: center;
  align-items: center;
  background: #083994;
  border-radius: 12px;
  padding: 12px 20px;
  color: white;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.064px;

  &:disabled {
    opacity: 0.5;
  }
`

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
`

const PolicySection = styled.section`
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-bottom: 32px;

  & > article {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);
    word-break: keep-all;
    &:first-child {
      padding-bottom: 8px;
    }
  }
`
