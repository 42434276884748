import React from 'react'
import ModalLayout, { ModalHeaderColorType } from '../../Layout/ModalLayout/ModalLayout'
import Button from '../Button'
import Spinner from '../Spinner'

export type AlertModalProps = {
  open: boolean
  title?: string
  headline?: string
  subline?: string
  confirmText?: string
  onConfirm: () => void | Promise<void>
  children?: React.ReactNode
  headerColor?: ModalHeaderColorType
  isConfirmLoading?: boolean
  buttonWidth?: number
  disabled?: boolean
  onClose?: () => void
}

export default function AlertModal({
  open,
  title,
  headline,
  subline,
  confirmText = '확인',
  onConfirm,
  children,
  headerColor = 'black',
  isConfirmLoading,
  buttonWidth = 120,
  disabled,
  onClose,
}: AlertModalProps) {
  return (
    <ModalLayout onClose={onClose ? onClose : isConfirmLoading ? undefined : onConfirm} open={open}>
      {title && <ModalLayout.Header color={headerColor}>{title}</ModalLayout.Header>}
      <ModalLayout.Body>
        {headline && (
          <ModalLayout.Headline>
            {headline.split('\\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </ModalLayout.Headline>
        )}
        {subline && (
          <ModalLayout.Subline>
            {subline.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </ModalLayout.Subline>
        )}
        {children}
      </ModalLayout.Body>
      <ModalLayout.Footer>
        <Button
          width={buttonWidth}
          onClick={isConfirmLoading ? undefined : onConfirm}
          buttonTheme={disabled ? 'disabled' : 'primary'}
        >
          {isConfirmLoading ? <Spinner color="white" /> : confirmText}
        </Button>
      </ModalLayout.Footer>
    </ModalLayout>
  )
}
