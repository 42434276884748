import styled from 'styled-components'

export function Introduce() {
  return (
    <Section>
      <TitleWrap>
        <FirstTitleLine>무인매장 관리 No.1</FirstTitleLine>
        <SecondTitleLine>현재 500개 관리 중</SecondTitleLine>
      </TitleWrap>

      <img src="/image/shinhancard/brwnie-introduce.webp" alt="브라우니 소개 이미지" width={290} height={290} />

      <Description>
        전문가의 노하우로
        <br />
        업종별 맞춤 관리를 제공합니다
      </Description>
    </Section>
  )
}

const Section = styled.section`
  margin-top: -44px;
  width: 100vw;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-top: 44px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  box-shadow: 0px -8px 12px 0px rgba(0, 0, 0, 0.08);
`

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`

const FirstTitleLine = styled.h4`
  font-family: 'GmarketSansBold';
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: #039;
`

const SecondTitleLine = styled.h4`
  font-family: 'GmarketSansBold';
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  color: #212121;
`

const Description = styled.span`
  margin-top: 20px;
  color: #757575;
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`
