import { useMutation } from 'react-query'
import client from '../../lib/api/client'
import axios from 'axios'

interface CollaboratorRegisterParams {
  keyCode: 'shinhancard'
}

interface CollaboratorRegisterRequestBody {
  name: string
  phone: string
  store_name: string
}

interface CollaboratorRegisterResponse {
  id: number // 쿠폰 id
  code: string // 쿠폰 코드 (영문 대소문자 구분)
  use: false // 쿠폰 사용 여부 (false: 발급 직후이니, 항상 false)
  createdAt: string // 쿠폰 생성일시
  updatedAt: string // 쿠폰 수정일시
  templateId: number // 쿠폰 발급에 사용된 Template id
  reason: string // 쿠폰 발급 사유 (이벤트 description)
  collaboratedUserId: number // 등록된 고객 정보의 id
}

export function useCollaboratorRegister() {
  return useMutation({
    mutationFn: async ({ keyCode, ...body }: CollaboratorRegisterParams & CollaboratorRegisterRequestBody) => {
      const response = await client.post<CollaboratorRegisterResponse>(
        `/auth-api/v1/collaborate/register/${keyCode}`,
        body
      )
      return response.data
    },
  })
}
