import './index.css'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AuthPage from './pages/AuthPage'
import PaymentHistoryPage from 'pages/PaymentHistoryPage'
import CouponPage from 'pages/CouponPage'
import PointPage from 'pages/PointPage'
import NoticePage from 'pages/NoticePage'
import AuthRoute from 'components/Route/AuthRoute'
import TempVerifyPage from 'pages/TempVerifyPage/TempVerifyPage'
import RedirectPage from 'pages/RedirectPage'
import ClosePage from 'pages/ClosePage'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ReactChannelIO } from 'react-channel-plugin'
import ScrollToTop from 'components/common/ScrollToTop'
import PageLogger from 'components/common/PageLogger'
import MyPage from 'pages/MyPage'
import StorePostPage from 'pages/StorePostPage'
import ServicePaymentPage from 'pages/ServicePaymentPage'
import ServiceCompletePage from 'pages/ServiceCompletePage'
import StoreDetailPage from 'pages/StoreDetailPage'
import StoreModifyPage from 'pages/StoreModifyPage'
import FaqPage from 'pages/FaqPage'
import StorePage from 'pages/StorePage'
import MapPage from 'pages/MapPage'
import LandingPage from 'pages/LandingPage'
import ServiceDetailPage from 'pages/ServiceDetailPage'
import InAppAlert from 'components/InAppAlert'
import SamsungAlert from 'components/SamsungAlert'
import ServicePostPage from 'pages/ServicePostPage'
import EasyConsultingPage from 'pages/EasyConsultingPage'
import { useEffect } from 'react'
import Toast from 'components/common/Toast'
import { Popup } from 'components/Popup'
import ConsultingPage from 'pages/ConsultingPage'
import CsPrePage from 'pages/CsPrePage'
import NewCrewPage from 'pages/NewCrewPage'
import NewCrewCompletePage from 'pages/NewCrewCompletePage'
import WooribankPage from 'pages/WooriBankPage'
import ShinhanCardPage from 'pages/ShinhanCardPage'

Sentry.init({
  dsn: 'https://45805afa158e4b3bb77977a062d09277@o269300.ingest.sentry.io/5840548',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  })

  /**
   * @function androidMobileFullHeightSetting
   * @description 안드로이드기기에서 100vh를 계산해주는 함수
   */
  function androidMobileFullHeightSetting() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--aosvh', `${vh}px`)
  }

  useEffect(() => {
    document.body.classList.add('brwnie')
    androidMobileFullHeightSetting()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet>
          {process.env.REACT_APP_ENV === 'develop' ? <meta name="robots" content="noindex,nofollow" /> : <></>}
          <script src="https://developers.kakao.com/sdk/js/kakao.js"></script>
        </Helmet>
        <ReactChannelIO
          pluginKey="8f6d65f6-c568-4b94-801b-a3637a605826"
          autoBoot
          zIndex={10000005}
          // (신규 크루 정보 입력 & 입력 완료) + (브라우니 x 우리은행) 페이지에서는 채널톡 숨김
          hideChannelButtonOnBoot={
            window.location.href.includes('new-crew') || window.location.href.includes('wooribank')
          }
        >
          <Router>
            <ScrollToTop />
            <PageLogger />
            <Switch>
              <Route path="/" exact>
                <LandingPage />
              </Route>

              <Route path="/shinhancard" exact>
                <ShinhanCardPage />
              </Route>

              <Route path="/wooribank" exact>
                <WooribankPage />
              </Route>

              <Route path="/easy-consulting">
                <EasyConsultingPage />
              </Route>
              <AuthRoute path="/service-post/:storeId">
                <ServicePostPage />
              </AuthRoute>
              <Route path="/auth">
                <AuthPage />
              </Route>
              <AuthRoute path="/payment">
                <PaymentHistoryPage />
              </AuthRoute>
              <AuthRoute path="/coupon">
                <CouponPage />
              </AuthRoute>
              <AuthRoute path="/notice">
                <NoticePage />
              </AuthRoute>
              <AuthRoute path="/mypage">
                <MyPage />
              </AuthRoute>
              <Route path="/verify">
                <TempVerifyPage />
              </Route>
              <AuthRoute path="/point">
                <PointPage />
              </AuthRoute>
              <Route path="/close">
                <ClosePage />
              </Route>
              <AuthRoute path="/service-payment/:serviceId">
                <ServicePaymentPage />
              </AuthRoute>
              <AuthRoute path="/service-complete/:serviceId">
                <ServiceCompletePage />
              </AuthRoute>
              <AuthRoute path="/store-post">
                <StorePostPage />
              </AuthRoute>
              <AuthRoute path="/store-detail/:storeId">
                <StoreDetailPage />
              </AuthRoute>
              <Route path="/faq">
                <FaqPage />
              </Route>
              <Route path="/map">
                <MapPage />
              </Route>
              <AuthRoute path="/store-modify/:storeId">
                <StoreModifyPage />
              </AuthRoute>
              <Route path="/service-detail/:type">
                <ServiceDetailPage />
              </Route>
              <AuthRoute path="/store">
                <StorePage />
              </AuthRoute>
              <Route path="/muin-consulting">
                <ConsultingPage />
              </Route>
              <Route path="/cs-pre">
                <CsPrePage />
              </Route>
              <Route path="/new-crew">
                <NewCrewPage />
              </Route>
              <Route path="/new-crew-complete">
                <NewCrewCompletePage />
              </Route>
              <Route path="/redirect">
                <RedirectPage />
              </Route>
              <Redirect to="/" path="*" />
            </Switch>
          </Router>
        </ReactChannelIO>
      </HelmetProvider>
      {/* 모달 */}
      <SamsungAlert />
      <InAppAlert />
      <Toast />
    </QueryClientProvider>
  )
}
