import styled from 'styled-components'

export function BrwnieBottomCtaSection() {
  return (
    <Section>
      <SubTitle>귀찮았던 매장 방문,</SubTitle>
      <Title>
        브라우니에 맡기고
        <br />
        진정한 무인으로 만드세요.
      </Title>
    </Section>
  )
}

const Section = styled.section`
  width: 100vw;

  display: flex;
  padding: 32px 30px 110px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`

const SubTitle = styled.p`
  color: #6e6e6e;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */

  margin-bottom: 8px;
`

const Title = styled.p`
  color: #212121;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36.4px; /* 140% */
`
