import { useHistory } from 'react-router'
import styled from 'styled-components'

import { ROUTES } from 'utils/common/routes'
import { UTM_URL } from 'utils/common/utmUrl'

export function StoreTypeSection() {
  const history = useHistory()
  const { push } = history

  return (
    <Section>
      <StoreTypeUl>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_ICE_CREAM + UTM_URL.LANDING_STORE_TYPE_ICE)}>
          <div className="icon">🍦</div>
          아이스크림
          <br />
          할인점
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_SELF_LAUNDRY + UTM_URL.LANDING_STORE_TYPE_LAUNDRY)}>
          <img className="img_icon" src="/image/브라우니_무인매장_셀프빨래방_이모지.png" alt="laundry" />
          셀프 빨래방
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.STUDIO)}>
          <div className="icon">📷</div>
          무인 사진관
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_CONVENIENCE + UTM_URL.LANDING_STORE_TYPE_CONVENIENCE)}>
          <div className="icon">🧃</div>
          무인 편의점
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_CAFE + UTM_URL.LANDING_STORE_TYPE_CAFE)}>
          <div className="icon">☕️</div>
          무인 카페
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_STUDY_CAFE + UTM_URL.LANDING_STORE_TYPE_STUDY_CAFE)}>
          <div className="icon">📖</div>
          스터디 카페
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_SMART_MACHINE + UTM_URL.LANDING_STORE_TYPE_VENDING)}>
          <img className="img_icon" src="/image/브라우니_무인매장_무인자판기_이모지.png" alt="vendingmachine" />
          무인 자판기·
          <br />
          밀키트
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_STATIONERY)}>
          <div className="icon" style={{ transform: 'scaleX(-1)' }}>
            ✏️
          </div>
          무인 문구점
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_PRINTER_MACHINE)}>
          <img className="img_icon" src="/image/브라우니_무인매장_무인프린트_이모지.png" alt="printermachine" />
          무인 프린트
        </StoreTypeLi>
      </StoreTypeUl>
    </Section>
  )
}

const Section = styled.section`
  padding-bottom: 60px;
`

const StoreTypeUl = styled.ul`
  width: 328px;
  height: unset;

  margin: 0 auto;
  padding: 0px 40px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 6px;
  column-gap: 6px;

  justify-content: center;
  align-items: center;
`

const StoreTypeLi = styled.li`
  width: unset;
  height: unset;

  min-width: 90px;
  min-height: 90px;

  aspect-ratio: 1/1;

  cursor: pointer;
  border-radius: 12px;
  box-sizing: border-box;

  background-color: var(--gray0);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 16px;
  color: var(--gray700);
  text-align: center;

  .icon {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 8px;
  }

  .img_icon {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
  }

  &:hover {
    box-shadow: 0px 16px 16px -8px rgba(0, 0, 0, 0.12);
    transform: translateY(-12px);
  }
`
