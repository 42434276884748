import styled from 'styled-components'
import { ReactComponent as NaverLogo } from '../WooribankPage/naver-logo.svg'
import { ReactComponent as SearchIcon } from '../WooribankPage/search-icon.svg'
export function ServiceStepSection() {
  return (
    <Section>
      <Box>
        <NumSquare>
          <Num>1</Num>
        </NumSquare>

        <BoxTitle>
          <b>브라우니 홈페이지 접속(brwnie.kr)</b>
        </BoxTitle>

        <Box1Content>
          <SearchBar>
            <NaverLogo />
            브라우니 무인매장
            <SearchIcon />
          </SearchBar>
        </Box1Content>
      </Box>

      <Box>
        <NumSquare>
          <Num>2</Num>
        </NumSquare>

        <BoxTitle style={{ marginBottom: 20 }}>
          <b>회원가입 {' > '} 상단 메뉴 "마이페이지"</b>
        </BoxTitle>
        <img
          src="/image/shinhancard/step-2.webp"
          alt="회원가입 후 서비스 신청 예시 이미지"
          width={250}
          style={{ width: '100%', marginBottom: 20 }}
        />
      </Box>

      <Box>
        <NumSquare>
          <Num>3</Num>
        </NumSquare>

        <BoxTitle style={{ marginBottom: 20 }}>
          <b>사전에 받은 쿠폰 번호 등록</b>
        </BoxTitle>

        <img
          src="/image/shinhancard/step-3.webp"
          alt="브라우니 카카오톡 플러스 친구"
          width={250}
          style={{ width: '100%', marginBottom: 20 }}
        />
      </Box>

      <Box>
        <NumSquare>
          <Num>4</Num>
        </NumSquare>

        <BoxTitle style={{ marginBottom: 16 }}>
          <b>쿠폰 생성 완료 {' > '} 결제 시 사용</b>
        </BoxTitle>
        <img
          src="/image/shinhancard/step-4.webp"
          alt="회원가입 후 서비스 신청 예시 이미지"
          width={250}
          style={{ width: '100%', marginBottom: 20 }}
        />
      </Box>

      <Box4>
        <NumSquare style={{ top: 11 }}>
          <Num>5</Num>
        </NumSquare>

        <Box4ContentText>
          <b>
            <span
              style={{
                color: '#FCFF10',
              }}
            >
              결제시 5만원 할인 쿠폰 적용
            </span>
          </b>
          <br />
          <span>(쿠폰은 1회만 사용 가능)</span>
        </Box4ContentText>
      </Box4>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100vw;
  background-color: #d6e7fd;
`

const Box = styled.div`
  width: 308px;
  padding: 20px 24px 0;
  position: relative;
  border-radius: 14px;
  background-color: white;

  & + & {
    margin-top: 12px;
  }
`

const NumSquare = styled.div`
  border-radius: 10px;
  width: 36px;
  height: 36px;
  border: 2px solid #fff;
  background-color: #5363ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -22px;
  top: 14px;
`

const Num = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: white;
`

const BoxTitle = styled.span`
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.4px;

  & > b {
    font-weight: 700;
  }
`

const Box1Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 20px;
`

const SearchBar = styled.div`
  width: 100%;
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;

  border-radius: 12px;
  border: 2px solid #5dcb6a;
  background: #fff;
`

const Box3ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
  background-color: #f1f5f9;
`

const Box3ContentDescription = styled.span`
  margin-top: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.4px;

  b {
    font-weight: 700;
  }
`

const Box4 = styled.div`
  width: 308px;
  height: 58px;
  position: relative;
  margin-top: 12px;
  padding: 14px 26px;
  border-radius: 14px;
  background-color: #5363ff;
  border: 1px solid #185aff;
  display: flex;
  align-items: center;
`

const Box4ContentText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.4px;
  color: white;

  b {
    font-weight: 700;
    font-size: 18px;
  }
`
