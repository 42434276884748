import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export function CoworkCompanySection() {
  const history = useHistory()
  const { push } = history

  const handleConsulting = () => {
    push('/easy-consulting')
  }

  return (
    <Section>
      <SectionWrapper>
        <h1>
          이미 TOP 브랜드는
          <br />
          브라우니로 관리 중
        </h1>
        <MobileCoworkLogoImg src="/image/브라우니_무인매장_함께하는_기업들_m.png" alt="브라우니 무인매장" />
      </SectionWrapper>
    </Section>
  )
}

const Section = styled.section`
  width: 100vw;
  background-color: var(--gray100);
  padding: 40px 0px 30px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SectionWrapper = styled.div`
  width: 328px;

  background-color: var(--gray0);
  margin: 0px 16px;
  padding-top: 32px;
  padding-bottom: 52px;

  border-radius: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  & > h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    word-break: keep-all;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--gray800);

    width: 100%;
  }
`

const MobileCoworkLogoImg = styled.img`
  width: 100%;

  display: flex;
  height: auto;

  box-sizing: border-box;
  margin-top: 32px;

  object-fit: contain;
`
