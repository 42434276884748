import styled from 'styled-components'

export function BrwnieServiceAfter() {
  return (
    <Section>
      <Title>
        브라우니가
        <br />
        관리하면 다릅니다!
      </Title>

      <TagWrap>셀프빨래방</TagWrap>
      <ImageWrap>
        <img
          src="/image/shinhancard/service-before-after.webp"
          alt="브라우니 셀프빨래방 관리 전, 관리 후 이미지"
          width={300}
          height={225}
        />
      </ImageWrap>
    </Section>
  )
}

const Section = styled.section`
  padding: 40px 0px;
  backdrop-filter: blur(10px);
  background: linear-gradient(90deg, #e1f5fe 22.4%, rgba(225, 245, 254, 0) 100%),
    url(<path-to-image>) lightgray -786.461px 0px / 409.24% 100% no-repeat;
`

const Title = styled.p`
  color: #212121;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */

  margin-bottom: 44px;
`

const TagWrap = styled.div`
  width: fit-content;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #3d5afe;
  margin: 0 auto;

  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const ImageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 12px;
`
