import { AlbagoSection } from 'components/ShinhanCardPage/AlbagoSection'
import { BottomCTA } from 'components/ShinhanCardPage/BottomCTA'
import { BrwnieBottomCtaSection } from 'components/ShinhanCardPage/BrwnieBottomCtaSection'
import { BrwnieServiceAfter } from 'components/ShinhanCardPage/BrwnieServiceAfter'
import { Coupon } from 'components/ShinhanCardPage/Coupon'
import { CouponForm } from 'components/ShinhanCardPage/CouponForm'
import { CoworkCompanySection } from 'components/ShinhanCardPage/CoworkCompanySection'
import { Introduce } from 'components/ShinhanCardPage/Introduce'
import { ServiceStepSection } from 'components/ShinhanCardPage/ServiceStepSection'
import { StoreTypeSection } from 'components/ShinhanCardPage/StoreTypeSection'

export default function ShinhanCardPage() {
  return (
    <>
      <Coupon />
      <Introduce />
      <StoreTypeSection />
      <BrwnieServiceAfter />
      <CoworkCompanySection />
      <AlbagoSection />
      <CouponForm />
      <ServiceStepSection />
      <BrwnieBottomCtaSection />
      <BottomCTA />
    </>
  )
}
