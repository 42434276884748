import React, { useState } from 'react'
import { render } from 'react-dom'
import { BottomSheet, CheckOptionListRow, TypoC1 } from 'ohds-web'
import { selectCloseTime, serviceHourOptions, ServiceHourType } from 'modules/features/service/serviceSlice'
import { useAppSelector } from '../useReduxHook'
import styled from 'styled-components'

export default function useVisitHourBottomSheet() {
  const closeTimeSchedule = useAppSelector(selectCloseTime)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [bottomSheetTitle, setBottomSheetTitle] = useState<string>('')
  const [closeHourOptions, setCloseHourOptions] = useState<ServiceHourType[]>([])
  const [otherOption, setOtherOption] = useState<React.ReactNode>()

  const openVisitHourOptions = async ({
    title,
    year,
    month,
    date,
    OtherOption,
  }: {
    title: string
    year: number
    month: number
    date: number
    OtherOption?: React.ReactNode
  }) => {
    setBottomSheetTitle(title)
    const closeHourOptions = closeTimeSchedule
      .filter((schedule) => schedule.year === year && schedule.month === month && schedule.date === date)
      .map(({ hour }) => hour as ServiceHourType)

    setCloseHourOptions(closeHourOptions)

    setIsOpen(true)
    setOtherOption(OtherOption)

    const selectedVisitHour = await new Promise((resolve) => {
      setTimeout(() => {
        for (let i = 0; i < serviceHourOptions.length; i++) {
          // 저녁시간 마감 처리
          if (!closeHourOptions.includes(serviceHourOptions[i]) && (serviceHourOptions[i] <= 19 || serviceHourOptions[i] == 21)) {
            const element = document.getElementById(`visit-hour-option_${i}`)
            element?.addEventListener('click', () => {
              resolve(serviceHourOptions[i])
            })
          }
        }
      }, 100)
    })

    if (selectedVisitHour !== undefined) {
      setIsOpen(false)
      return selectedVisitHour as ServiceHourType
    }
  }

  render(
    <StyledBottomSheet
      open={isOpen}
      title={bottomSheetTitle}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      {serviceHourOptions.map((visitHourOption, i) => (
        <CheckOptionListRow
          id={`visit-hour-option_${i}`}
          key={visitHourOption}
          text={`${visitHourOption}시`}
          // 저녁시간 마감 처리
          disabled={closeHourOptions.includes(visitHourOption) || visitHourOption == 20}
          onClick={() => {}}
          Right={
            closeHourOptions.includes(visitHourOption) || visitHourOption == 20 ? (
              <DisableBadge>
                <TypoC1 color="gray600" text="마감" />
              </DisableBadge>
            ) : undefined
          }
        />
      ))}
      {otherOption && <div onClick={() => setIsOpen(false)}>{otherOption}</div>}
    </StyledBottomSheet>,
    document.getElementById('modal') as Element
  )

  return [openVisitHourOptions]
}

export const DisableBadge = styled.div`
  width: 42px;
  text-align: center;
  border-radius: 8px;
  background-color: var(--gray200);
`

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`
